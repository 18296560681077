
.MuiDataGrid-cell:focus{
    outline: none;
} 
.MuiDataGrid-cell:focus-within{
    outline: none;
} 

.cell:focus, .cell-focus-within{
    outline:none;
}
.cell{
    outline: transparent;
}

.custom-header {
    background-color: #fff9c4; /* Pale yellow color */
  }